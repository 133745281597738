<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <a-input v-model:value="unitSearchName" placeholder="Поиск по названию" @change="searchUnit" allow-clear />
      </div>
      <div class="col-md-9 text-right">
        <vb-create-unit :modal="createModal" :key="createModalKey" :locales="locales" @reload="reload()" />
      </div>
    </div>
    <vb-edit-unit
      v-if="editModal"
      :reference-id="unitId"
      :locales="locales"
      @reload="reload()"
      @hide-modal="hideEditModal()"
    />
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      @change="tableChange"
    >
      <template #name="{ record }">
        <a-tree
          :selectable="false"
          :load-data="onLoadLocales"
          v-model:expandedKeys="expandedKeys"
          :tree-data="treeData.find(item => item.id === record.id)?.locales"
        >
          <template #switcherIcon><down-outlined /></template>
        </a-tree>
      </template>
      <template #default="{ record }">
        <a-popconfirm
          v-if="record['default']"
          title="Вы уверены?"
          @confirm="updateColumn(record.id, record.title, 'default', 0)"
          @cancel="() => {}"
          ok-text="Да"
          cancel-text="Нет"
        >
          <a-tag
            color="#108ee9"
            class="cursor-pointer"
          >Да</a-tag>
        </a-popconfirm>
        <a-popconfirm
          v-else
          title="Установить по умолчанию?"
          @confirm="updateColumn(record.id, record.title, 'default', 1)"
          @cancel="() => {}"
          ok-text="Да"
          cancel-text="Нет"
        >
          <a-tag
            class="cursor-pointer"
          >Нет</a-tag>
        </a-popconfirm>
      </template>
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author">
          ({{ record.author }})
        </span>
      </template>
      <template #edited="{ record }">
        <span v-if="record['updated_on']">
          {{ formattedDatetime(record['updated_on']) }}
        </span>
        <span v-if="record.edited">
          ({{ record.edited }})
        </span>
      </template>
      <template #action="{ record }">
        <div class="text-right">
          <a-tooltip placement="top" class="mx-2" v-if="user.role !== 'manager'">
            <template #title>Редактировать</template>
            <a @click="showEditModal(record.id)"><i class="fe fe-edit"></i></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2" v-if="user.role !== 'manager'">
            <template #title>Удалить</template>
            <a @click="deleteModal(record.id)"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
  </div>
</template>

<script setup>
import { watch, computed, reactive, ref, toRefs, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import VbCreateUnit from "@/views/references/unit/create";
import VbEditUnit from "@/views/references/unit/edit";
import apiClient from "@/services/axios";
import { useStore } from "vuex";
import {message, Modal} from "ant-design-vue";
import { DownOutlined } from '@ant-design/icons-vue';

let
  unitSearchName = ref(''),
  dataSource = ref([]),
  loading = ref(false),
  createModal = ref(false),
  createModalKey = ref(0),
  editModal = ref(false),
  locales = ref([]),
  timer = null,
  total = ref(0),
  unitId = ref(0),
  treeData = ref([]);

const router = useRouter(),
  user = computed(() => useStore().getters['user/user']),
  expandedKeys = ref([]),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    title: '',
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'ID',
      key: 'key',
      dataIndex: 'id',
      sorter: true,
    },
    {
      title: 'Название',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      slots: {
        customRender: 'name',
      },
    },
    {
      title: 'По умолчанию',
      dataIndex: 'default',
      key: 'default',
      sorter: true,
      slots: {
        customRender: 'default',
      },
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      sorter: true,
      key: 'added_on',
      slots: { customRender: 'date' },
    },
    {
      title: 'Изменен',
      dataIndex: 'updated_on',
      sorter: true,
      key: 'updated_on',
      slots: { customRender: 'edited' },
    },
    {
      key: 'action',
      slots: { customRender: 'action' },
    },
  ],
  updateColumn = (id, title, column, value) => {
    let data = {};
    data['title'] = title;
    data[column] = value;
    apiClient.put(`/unit/update?id=${id}`, data).then(response => {
      if (response) {
        message.success('Ед. измерения успешно обновлена')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
    });
  },
  onLoadLocales = treeNode => {
    return new Promise(resolve => {
      if (treeNode.dataRef.children) {
        resolve();
        return;
      }
      apiClient.get(`/unit/view?id=${treeNode.dataRef.key}`)
        .then(({ data }) => {
          if (data) {
            treeNode.dataRef.children = []
            data.data.locales.forEach((item) => {
              const model = locales.value.find(locale => locale?.short === item.locale)
              treeNode.dataRef.children.push({
                title: `${model.name}: ${item.title}`,
                key: item.id,
                children: [],
              })
            })
          }
        }).catch(err => {
        })
        .finally(() => {
          resolve()
        })

    });
  },
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({ sort: sign + sorter.field })
    }
  },
  searchUnit = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ title: e.target.value })
    }, 800);
  },
  showEditModal = (id) => {
    unitId.value = id
    editModal.value = true;
  },
  hideEditModal = () => {
    setTimeout(() => {
      editModal.value = false
    }, 100)
  },
  deleteModal = id => {
    Modal.confirm({
      title: 'Удаление записи',
      content: `Вы действительно хотите удалить запись?`,
      okText: 'Да',
      onOk() {
        return apiClient.delete(`unit/delete?id=${id}`).then(response => {
          if (response) {
            message.success('Запись успешно удалена')
          }
        }).catch(() => {
          // console.log(err)
        }).finally(() => {
          reload()
        });
      },
    });
  },
  formattedDatetime = (date) => {
    date = date.replace(/ /g,"T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  getData = (params) => {
    loading.value = true
    return apiClient.get('/unit/index',{ params }).then(({data}) => {
      treeData.value = []
      data.data.items.forEach((item) => {
        treeData.value.push({
          id: item.id,
          locales: [{
            title: item.title,
            key: item.id,
          }],
        })
      })
      dataSource.value = data.data.items
      total.value = data.data.total
      locales.value = data.data.locales
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };

onMounted(() => {
  window.addEventListener('keydown', (e) => {
    if (e.key === 'Enter' && e.metaKey) {
      createModal.value = true
      createModalKey.value++
    }
  });
  getData({
    ...queryParams,
  })
})

watch(queryParams, () => {
  router.push({
    name: 'unit',
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
})

const query = {...toRefs(queryParams)}

</script>

<style scoped src="./index.css"></style>
