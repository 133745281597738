<template>
  <a-button class="editable-add-btn" @click="openModal" style="margin-bottom: 16px">Добавить</a-button>
  <a-modal
    class="reference-create-modal"
    title="Добавить запись"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="create"
    @cancel="resetForm"
  >
    <a-form :model="reference" @keyup.enter="create">
      <div class="col-md-12">
        <a-form-item label="Название записи">
          <a-input
            ref="referenceName"
            placeholder="Введите название записи"
            v-model:value="reference.title"
          />
        </a-form-item>
        <a-form-item label="Переводы" v-if="reference.locales.length">
          <div class="container pb-3" v-for="(model, key) in reference.locales" :key="key">
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <a-input placeholder="Введите название" v-model:value="model.title"></a-input>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <a-select class="pl-3" v-model:value="model.locale">
                    <a-select-option v-for="(locale, lKey) in locales" :key="lKey" :value="locale.short">{{ locale.name }}</a-select-option>
                  </a-select>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-button
          @click="addLocale"
          shape="round"
          type="link"
          class="px-0"
        >
          <template #icon>
            <PlusCircleOutlined />
          </template>Добавить перевод</a-button>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, reactive, toRaw, nextTick, watch} from 'vue';
import apiClient from "@/services/axios";
import Reference from "@/models/Reference";
import { PlusCircleOutlined } from '@ant-design/icons-vue';

const props = defineProps({
  locales: {
    type: Array,
    default: null,
  },
  modal: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['reload']);

let loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  initialReference = new Reference({}),
  referenceName = ref("referenceName"),
  reference = reactive(initialReference);

const
  addLocale = () => {
    reference.locales.push({
      id: null,
      title: null,
      locale: props.locales.find(() => true).short,
    })
  },
  openModal = () => {
    modalVisible.value = true;
    nextTick(() => referenceName.value?.focus());
  },
  resetForm = () => {
    Object.assign(reference, new Reference({}));
    modalVisible.value = false;
  },
  create = () => {
    confirmLoading.value = true;

    let model = toRaw(reference);

    return apiClient.post('/unit/create', model).then(function (response) {
      if (response) {
        resetForm()
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      modalVisible.value = false;
      confirmLoading.value = false;
      emit('reload')
    })
  }

watch(() => {
  if (props.modal) {
    openModal()
  }
})
</script>

<style scoped>
@import "./create.css";
</style>
