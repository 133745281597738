<template>
  <a-modal
    class="reference-edit-modal"
    title="Редактировать запись"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <a-form :model="reference" @keyup.enter="update">
      <div class="col-md-12">
        <a-form-item label="ID">
          <a-input
            placeholder="Введите ID"
            v-model:value="reference.key"
          />
        </a-form-item>
        <a-form-item label="Название записи">
          <a-input
            ref="referenceName"
            placeholder="Введите название записи"
            v-model:value="reference.title"
          />
        </a-form-item>
        <a-form-item label="Переводы" v-if="reference.locales.length">
          <div class="container pb-3" v-for="(model, key) in reference.locales" :key="key">
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <a-input placeholder="Введите название" v-model:value="model.title"></a-input>
                </div>
              </div>
              <div class="col-md-3">
                <div class="row">
                  <a-select class="pl-3" v-model:value="model.locale">
                    <a-select-option v-for="(locale, lKey) in locales" :key="lKey" :value="locale.short">{{ locale.name }}</a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="col-md-1">
                <div class="row">
                  <a-button @click="removeLocale(key)" type="link" danger><MinusCircleOutlined /></a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-button
          @click="addLocale"
          shape="round"
          type="link"
          class="px-0"
        >
          <template #icon>
            <PlusCircleOutlined />
          </template>Добавить перевод</a-button>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, reactive, toRaw, onMounted, nextTick} from 'vue';
import apiClient from "@/services/axios";
import Reference from "@/models/Reference";
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons-vue";

const props = defineProps({
  locales: {
    type: Array,
    default: null,
  },
  referenceId: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let loading = ref(true),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  referenceName = ref("referenceName"),
  initialReference = new Reference({}),
  reference = reactive(initialReference);

const
  addLocale = () => {
    reference.locales.push({
      id: null,
      title: null,
      locale: props.locales.find(() => true).short,
    })
  },
  removeLocale = (key) => {
    reference.locales.splice(key, 1)
  },
  resetForm = () => {
    Object.assign(reference, new Reference({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(reference)

    return apiClient.put(`/unit/update?id=${props.referenceId}`, model).then(response => {
      if (response) {
        resetForm()
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      modalVisible.value = false;
      emit('reload')
      emit('hide-modal')
    })
  };

apiClient.get(`/unit/view?id=${props.referenceId}`).then(response => {
  if (response) {
    let model = new Reference(response.data.data)
    Object.assign(reference, model)
  }
}).catch(() => {
  // console.log(err)
}).finally(() => {
  loading.value = false
})

onMounted(() => nextTick(() => referenceName.value?.focus()))
</script>

<style scoped>
@import "./edit.css";
</style>
